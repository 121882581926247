/* eslint-disable react/no-children-prop */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as React from "react"
import { createRoot } from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import {
  fetchBrandMarketPageData,
  fetchBrandPageData,
  fetchBrandsPageData,
  fetchDownloadsPageData,
  fetchFaqPageData,
  fetchGlobalData,
  fetchHomePageData,
  fetchInfoPageData,
  fetchNewsArticlePageData,
  fetchNewsPageData,
  fetchRegistrationPageData,
  fetchTestimonialsPageData
} from "./helpers/getData"
import { RootLayout } from "./pages/RootLayout"
import * as serviceWorker from "./serviceWorker"

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader: async () => fetchGlobalData(),
    element: <RootLayout />,
    children: [
      {
        index: true,
        lazy: () => import("./pages/Home"),
        loader: async () => fetchHomePageData()
      },
      {
        id: "brands",
        path: "/brands",
        lazy: () => import("./pages/Brands"),
        loader: async () => fetchBrandsPageData()
      },
      {
        path: "/brands/:brandSlug",
        lazy: () => import("./pages/Brand"),
        loader: async ({ params }) => fetchBrandPageData(params.brandSlug)
      },
      {
        path: "/brands/:brandSlug/:marketSlug",
        lazy: () => import("./pages/Market"),
        loader: async ({ params }) => fetchBrandMarketPageData(params.brandSlug, params.marketSlug)
      },
      {
        path: "/testimonials",
        lazy: () => import("./pages/Testimonials"),
        loader: async () => fetchTestimonialsPageData()
      },
      {
        path: "/faq",
        lazy: () => import("./pages/Faq"),
        loader: async () => fetchFaqPageData()
      },
      {
        path: "/downloads",
        lazy: () => import("./pages/Downloads"),
        loader: async () => fetchDownloadsPageData()
      },
      {
        path: "/register",
        lazy: () => import("./pages/Registration"),
        loader: async () => fetchRegistrationPageData()
      },
      {
        path: "/news",
        lazy: () => import("./pages/News"),
        loader: async ({ request }) => fetchNewsPageData(new URL(request.url).searchParams)
      },
      {
        path: "/news/:slug",
        lazy: () => import("./pages/NewsArticle"),
        loader: async ({ params }) => {
          const article = await fetchNewsArticlePageData(params.slug)

          if (!article) {
            throw new Response("Not Found", { status: 404 })
          }

          return article
        }
      },
      {
        path: "/:slug",
        lazy: () => import("./pages/Info"),
        loader: async ({ params }) => fetchInfoPageData(params.slug)
      },
      {
        path: "*",
        lazy: () => import("./pages/NotFound")
      }
    ]
  }
])

const App = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}

createRoot(document.getElementById("root")).render(<App />)

serviceWorker.unregister()
