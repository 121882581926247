/* eslint-disable no-return-await */
/* eslint-disable no-param-reassign */

const {
  REACT_APP_CONTENTFUL_SPACE_ID: contentfulSpaceId,
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: contentfulAccessToken,
  REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN: contentfulPreviewAccessToken,
  REACT_APP_MODE: mode
} = process.env

const accessToken = mode === "preview" ? contentfulPreviewAccessToken : contentfulAccessToken

const isPreview = mode === "preview"

const fetchGraphql = async query => {
  const res = await fetch(`https://graphql.contentful.com/content/v1/spaces/${contentfulSpaceId}/environments/master`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ query })
  })

  return await res.json()
}

export const fetchGlobalData = async () => {
  const res = await fetchGraphql(`
    query {
      homepageCollection(preview: ${isPreview}, where: { language: "en" }, limit: 1) {
        items {
          logo {
            url(transform: { format: WEBP, quality: 90 })
          }
          seoTitle
          seoDescription
          footerLinks
          socialIcons
          registrationForm
        }
      }
      pagesCollection(preview: ${isPreview}, where: { language: "en" }) {
        items {
          slug
          pageName
        }
      }
      translationsCollection(preview: ${isPreview}, where: { language: "en" }, limit: 1) {
        items {
          translations
        }
      }
      brandCollection(preview: ${isPreview}, where: { language: "en" }) {
        items {
          loginUrls
        }
      }
    }  
  `)

  // Error handling

  const { logo, footerLinks, socialIcons, seoTitle, seoDescription, registrationForm } = res.data.homepageCollection.items[0]
  const legalPages = res.data.pagesCollection.items
  const { translations } = res.data.translationsCollection.items[0]
  const brands = res.data.brandCollection.items

  return {
    logo: logo.url,
    registrationForm,
    navLinks: [
      {
        text: `${translations.news}`,
        to: `/${translations.news.toLowerCase()}`,
        matchPath: `/${translations.news.toLowerCase()}`
      },
      {
        text: `${translations.brands}`,
        to: `/${translations.brands.toLowerCase()}`,
        matchPath: `/${translations.brands.toLowerCase()}`
      },
      {
        text: `${translations.testimonials}`,
        to: `/${translations.testimonials.toLowerCase()}`,
        matchPath: `/${translations.testimonials.toLowerCase()}`
      },
      {
        text: `${translations.faq.toUpperCase()}`,
        to: `/${translations.faq}`,
        matchPath: `/${translations.faq}`
      },
      {
        text: translations.downloads,
        to: `/${translations.downloads}`,
        matchPath: `/${translations.downloads}`
      }
    ],
    brandLoginOptions: brands
      .reduce((tot, curr) => {
        const { loginUrls } = curr

        if (!loginUrls || loginUrls.length === 0) return tot

        loginUrls.map(({ name, loginUrl }) => tot.push({ name, loginUrl }))

        return tot
      }, [])
      .sort((a, b) => (b.name > a.name ? -1 : 1)),
    seo: {
      title: seoTitle,
      description: seoDescription
    },
    footer: {
      legalLinks: legalPages.map(({ pageName, slug }) => ({ label: pageName, slug })),
      ourSitesLinks: footerLinks,
      socialLinks: socialIcons
    },
    translations
  }
}

export const fetchHomePageData = async () => {
  const res = await fetchGraphql(`
    query {
      homepageCollection(preview: ${isPreview}, where: { language: "en" }, limit: 1) {
        items {
          aboutTitle
          aboutText
          commissionTitle
          commissionText
          value1
          value1Text
          value2
          value2Text
          value3
          value3Text
          value4
          value4Text
          bannerSectionCollection(limit: 1) {
            items {
              bannerImage {
                url
              }
              mobileMedia {
                url
              }
            }
          }
        }
      }
      brandCollection(preview: ${isPreview}, where: { language: "en" }, order: order_ASC) {
        items {
          brandName
          brandLogo {
            url(transform: { format: WEBP, quality: 90, width: 194 })
          }
          slug
        }
      }
      translationsCollection(preview: ${isPreview}, where: { language: "en" }, limit: 1) {
        items {
          translations
        }
      }
      newsCollection(
        preview: ${isPreview},
        where: {AND: [{language: "en"}, {OR: [{hideFromNewsFeed_exists: false}, {hideFromNewsFeed: false}]}]}
        order: newsDate_DESC
        limit: 4
      ) {
        items {
          newsTitle
          newsThumbnail {
            url(transform: {format: WEBP, quality: 90, width: 284})
          }
          slug
          categoryCollection {
            items {
              categoryName
            }
          }
        }
      }
      testimonialsCollection(preview: ${isPreview}, where: { language: "en" }, limit: 1) {
        items {
          testimonialsListCollection {
            items {
              affiliateName
              text
              url
              affiliateImage {
                url(transform: { format: WEBP, quality: 90, width: 95 })
              }
            }
          }
        }
      }
    }
  `)

  // Error handling

  const { aboutTitle, aboutText, commissionTitle, commissionText, value1, value1Text, value2, value2Text, value3, value3Text, value4, value4Text } =
    res.data.homepageCollection.items[0]
  const brands = res.data.brandCollection.items
  const news = res.data.newsCollection.items
  const testimonials = res.data.testimonialsCollection.items[0].testimonialsListCollection.items
  const banner = res.data.homepageCollection.items[0].bannerSectionCollection.items[0]

  return {
    banner: {
      mobileMediaUrl: banner.mobileMedia.url,
      desktopMediaUrl: banner.bannerImage.url
    },
    brands: [
      ...brands.map(({ brandName, brandLogo, slug }) => ({
        name: brandName,
        logoUrl: brandLogo.url,
        slug
      }))
    ],
    about: {
      title: aboutTitle,
      text: aboutText
    },
    commission: {
      title: commissionTitle,
      text: commissionText,
      values: [
        {
          value: value1,
          text: value1Text
        },
        {
          value: value2,
          text: value2Text
        },
        {
          value: value3,
          text: value3Text
        },
        {
          value: value4,
          text: value4Text
        }
      ]
    },
    news: news.map(({ newsTitle, newsThumbnail: { url: imageUrl }, slug, categoryCollection }) => ({
      title: newsTitle,
      imageUrl,
      slug,
      categories: categoryCollection.items.reduce((tot, cur) => [...tot, cur.categoryName], [])
    })),
    testimonials: testimonials.map(({ affiliateName, affiliateImage: { url: logoUrl }, url, text }) => ({
      name: affiliateName,
      logoUrl,
      url,
      text
    }))
  }
}

export const fetchBrandsPageData = async () => {
  const res = await fetchGraphql(`
    query {
      brandCollection(preview: ${isPreview}, where: { language: "en" }, order: order_ASC) {
        items {
          brandLogo {
            url(transform: { format: WEBP, quality: 90, width: 194 })
          }
          slug
        }
      }
    }  
  `)

  // Error handling

  const brands = res.data.brandCollection.items

  return {
    brands: brands.map(({ brandLogo: { url }, slug }) => ({
      brandLogo: url,
      slug
    }))
  }
}

export const fetchBrandPageData = async slug => {
  const res = await fetchGraphql(`
    query {
      brandCollection(preview: ${isPreview}, where: { language: "en", slug: "${slug}" }) {
        items {
          brandPageLogo {
            url(transform: { format: WEBP, quality: 95, width: 245 })
          }
          slug
          brandName
          brandDescription
          brandBannerImageDesktop {
            url(transform: { format: WEBP, quality: 90 })
          }
          brandBannerImageMobile {
            url(transform: { format: WEBP, quality: 90})
          }
          brandUrl
          showRegisterButton
          markets
          feature1Icon {
            url(transform: { format: WEBP, quality: 90})
          }
          feature1Title
          feature2Icon {
            url(transform: { format: WEBP, quality: 90})
          }
          feature2Title
          feature3Icon {
            url(transform: { format: WEBP, quality: 90})
          }
          feature3Title
          seoTitle
          seoDescription
        }
      }
      marketCollection(preview: ${isPreview}, where: { language: "en", brandId: "${slug}" }, order: countryCode_ASC) {
        items {
          countryCode
          countryFlag {
            url(transform: { format: WEBP, quality: 90 })
          }
        }
      }
    }  
  `)

  // Error handling

  const {
    brandName: name,
    brandPageLogo: { url: logoUrl },
    slug: brandSlug,
    brandDescription: description,
    brandBannerImageDesktop: { url: desktopBannerImageUrl },
    brandBannerImageMobile: { url: mobileBannerImageUrl },
    brandUrl,
    showRegisterButton,
    feature1Icon: { url: feature1Icon },
    feature1Title: feature1Label,
    feature2Icon: { url: feature2Icon },
    feature2Title: feature2Label,
    feature3Icon: { url: feature3Icon },
    feature3Title: feature3Label,
    seoTitle,
    seoDescription
  } = res.data.brandCollection.items[0]

  const markets = res.data.marketCollection.items

  return {
    name,
    logoUrl,
    slug: brandSlug,
    description,
    mobileBannerImageUrl,
    desktopBannerImageUrl,
    url: brandUrl,
    showRegisterButton,
    markets: markets.map(({ countryCode, countryFlag: { url } }) => ({ countryCode, flagUrl: url })),
    features: [
      {
        iconUrl: feature1Icon,
        label: feature1Label
      },
      {
        iconUrl: feature2Icon,
        label: feature2Label
      },
      {
        iconUrl: feature3Icon,
        label: feature3Label
      }
    ],
    seoTitle,
    seoDescription
  }
}

export const fetchBrandMarketPageData = async (brandSlug, marketSlug) => {
  const res = await fetchGraphql(`
    query {
      brandCollection(preview: ${isPreview}, where: { language: "en", slug: "${brandSlug}" }) {
        items {
          brandPageLogo {
            url(transform: { format: WEBP, quality: 95, width: 254 })
          }
          slug
          brandName
        }
      }
      marketCollection(preview: ${isPreview}, where: { language: "en", brandId: "${brandSlug}", countryCode: "${marketSlug}" }) {
        items {
          brandId
          countryCode
          countryFlag {
            url(transform: { format: WEBP, quality: 90 })
          }
          seoTitle
          seoDescription
          marketTitle
          description
          content
        }
      }
    }
  `)

  // Error handling

  const {
    brandPageLogo: { url: brandPageLogoUrl },
    slug,
    brandName
  } = res.data.brandCollection.items[0]

  const { brandId, countryCode, seoTitle, seoDescription, marketTitle, description, content } = res.data.marketCollection.items[0]

  return {
    countryCode,
    brandId,
    brandName,
    slug,
    brandPageLogoUrl,
    title: marketTitle,
    seoTitle,
    seoDescription,
    description,
    content
  }
}

export const fetchTestimonialsPageData = async () => {
  const res = await fetchGraphql(`
    query {
      testimonialsCollection(preview: ${isPreview}, where: { language: "en" }, limit: 1) {
        items {
          testimonialsListCollection {
            items {
              affiliateName
              text
              url
              affiliateImage {
                url(transform: { format: WEBP, quality: 90, width: 95 })
              }
            }
          }
        }
      }
    }
  `)

  // Error handling

  const testimonials = res.data.testimonialsCollection.items[0].testimonialsListCollection.items

  return {
    testimonials: testimonials.map(({ affiliateName, text, url, affiliateImage }) => ({
      affiliateName,
      text,
      url,
      affiliateLogoUrl: affiliateImage.url
    }))
  }
}

export const fetchFaqPageData = async () => {
  const res = await fetchGraphql(`
    query {
      faqCollection(preview: ${isPreview}, where: { language: "en" }, order: order_ASC) {
        items {
          question
          answer
        }
      }
    }  
  `)

  // Error handling

  const faqs = res.data.faqCollection.items

  return {
    faqs
  }
}

export const fetchDownloadsPageData = async () => {
  const res = await fetchGraphql(`
    query {
      brandCollection(preview: ${isPreview}, where: { language: "en" }, order: order_ASC) {
        items {
          brandName
          brandLogo {
            url(transform: { format: WEBP, quality: 90, width: 215 })
          }
          downloadableLogo {
            fileName
            url(transform: { format: WEBP, quality: 90 })
          }
        }
      }
    }    
  `)

  // Error handling

  const downloads = res.data.brandCollection.items

  return {
    downloads: downloads.map(({ brandName, brandLogo, downloadableLogo }) => ({
      brandName,
      brandLogoUrl: brandLogo.url,
      downloadableLogoUrl: downloadableLogo.url,
      fileName: downloadableLogo.fileName
    }))
  }
}

export const fetchRegistrationPageData = async () => {
  const res = await fetchGraphql(`
    query {
      registrationCollection(preview: ${isPreview}, where: { language: "en" }) {
        items {
          popupContent
          popupCloseButtonText
        }
      }
    }    
  `)

  // Error handling

  return {
    popup: {
      content: res.data.registrationCollection.items[0].popupContent,
      closeButtonText: res.data.registrationCollection.items[0].popupCloseButtonText
    }
  }
}

export const fetchInfoPageData = async slug => {
  const res = await fetchGraphql(`
    query {
      pagesCollection(preview: ${isPreview}, where: { language: "en", slug: "${slug}" }, limit: 1) {
        items {
          title
          content
          content2
        }
      }
    }  
  `)

  // Error handling

  const { title, content, content2 } = res.data.pagesCollection.items[0]

  return {
    title,
    content,
    content2
  }
}

const numberOfArticlesPerPage = 8
export const fetchNewsPageData = async searchParams => {
  const page = searchParams.get("page")
  const brand = searchParams.get("brand")
  const search = searchParams.get("search")

  const res = await fetchGraphql(`
    query {
      articles: newsCollection(preview: ${isPreview}, where: {
        language: "en"
        ${brand ? `, category: { categoryName: "${brand}" }` : ""}
        AND: [
          {
            OR: [
              {
                AND: {
                  hideFromNewsFeed_exists: true
                  hideFromNewsFeed: false
                }
              }
              { hideFromNewsFeed_exists: false }
            ]
          }
          ${
            search
              ? `{
            OR: {
              newsTitle_contains: "${search}"
              newsText_contains: "${search}"
            }
          }`
              : ""
          }
        ]
      }) {
        numberOfArticles: total
      }
      newsCategoryCollection(preview: ${isPreview}, where: { language: "en", categoryType: "brands" }) {
        items {
          categoryName
        }
      }
      newsCollection(preview: ${isPreview}, where: {
        language: "en"
        ${brand ? `, category: { categoryName: "${brand}" }` : ""}
        AND: [
          {
            OR: [
              {
                AND: {
                  hideFromNewsFeed_exists: true
                  hideFromNewsFeed: false
                }
              }
              { hideFromNewsFeed_exists: false }
            ]
          }
          ${
            search
              ? `{
            OR: {
              newsTitle_contains: "${search}"
              newsText_contains: "${search}"
            }
          }`
              : ""
          }
        ]
      } order: newsDate_DESC limit: ${numberOfArticlesPerPage} skip: ${page ? (page - 1) * 8 : 0}) {
        items {
          newsThumbnail {
            url(transform: { format: WEBP, quality: 90, width: 275 })
          }
          newsTitle
          categoryCollection {
            items {
              categoryName
            }
          }
          slug
        }
      }
    }
  `)

  const articles = res.data.newsCollection.items
  const { numberOfArticles: numberOfTotalArticles } = res.data.articles
  const categories = res.data.newsCategoryCollection.items

  return {
    numberOfArticlesPerPage,
    numberOfTotalArticles,
    categories: categories.map(({ categoryName }) => ({ label: categoryName, value: categoryName })),
    articles: articles.map(({ newsThumbnail: { url }, slug, newsTitle, categoryCollection }) => ({
      imageUrl: url,
      title: newsTitle,
      slug,
      categories: categoryCollection.items.reduce((tot, category) => {
        if (!category) return tot

        return tot.concat(category.categoryName)
      }, [])
    }))
  }
}

export const fetchNewsArticlePageData = async slug => {
  const res = await fetchGraphql(`
    query {
      newsCollection(
        preview: ${isPreview},
        where: { language: "en", slug: "${slug}" }
        limit: 1
      ) {
        items {
          seoTitle
          seoDescription
          newsImage {
            url(transform: { format: WEBP, quality: 90, width: 1070 })
          }
          newsDate
          categoryCollection {
            items {
              categoryName
            }
          }
          newsTitle
          newsText
          downloadableLogosCollection {
            items {
              description
              fileName
              url
            }
          }
          hasRace
          raceTitle
          raceStartDate
          raceEndDate
          raceLeaderboardTitle
          raceLeaderboard
          raceLeaderboardTwo
          raceLeaderboardTwoTitle
        }
      }
    }
  `)

  const article = res?.data?.newsCollection?.items?.[0]

  if (!article) {
    return null
  }

  const {
    seoTitle,
    seoDescription,
    newsImage,
    newsDate,
    categoryCollection,
    newsTitle,
    newsText,
    downloadableLogosCollection,
    raceTitle,
    raceStartDate,
    raceEndDate,
    raceLeaderboardTitle,
    raceLeaderboard,
    raceLeaderboardTwoTitle,
    raceLeaderboardTwo
  } = article

  return {
    seoTitle,
    seoDescription,
    slug,
    bannerImageUrl: newsImage.url,
    date: newsDate,
    categories: categoryCollection.items.reduce((tot, category) => {
      if (!category) return tot

      return [...tot, category.categoryName]
    }, []),
    title: newsTitle,
    text: newsText,
    downloadableLogos: downloadableLogosCollection.items,
    race: {
      raceTitle,
      raceStartDate,
      raceEndDate,
      raceLeaderboardTitle,
      raceLeaderboard,
      raceLeaderboardTwoTitle,
      raceLeaderboardTwo
    }
  }
}

export const fetchRelatedNewsArticles = async (slugToIgnore, categories) => {
  const _categories = categories.map(cat => `"${cat}"`).join(",")

  const res = await fetchGraphql(`
    query {
      newsCollection(
        preview: ${isPreview}, 
        where: { language: "en", slug_not: "${slugToIgnore}", category: { categoryName_in: [${_categories}] } }
        limit: 4
      ) {
        items {
          slug
          newsTitle
          newsThumbnail {
            url(transform: {
              format: WEBP
              quality: 90
              width: 270
            })
          }
          categoryCollection {
            items {
              categoryName
            }
          }
        }
      }
    }
  `)

  const articles = res.data.newsCollection.items

  return articles.map(({ newsThumbnail: { url }, slug, newsTitle, categoryCollection }) => ({
    imageUrl: url,
    title: newsTitle,
    slug,
    categories: categoryCollection.items.reduce((tot, articleCategory) => {
      if (!articleCategory) return tot

      return [...tot, articleCategory.categoryName]
    }, [])
  }))
}
